import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A talk entitled `}<strong parentName="p">{`The creation of the African margins and the Mesozoic demise of Gondwana`}</strong>{` was presented at the 'Africa' meeting of the Petroleum Exploration Society of Great Britain (PESGB) in London, October 2019.`}</p>
    <p>{`Click on the following link to see the 4-page `}<strong parentName="p">{`Abstract`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.reeves.nl/upload/The-creation-of-the-African-margins-revised.pdf"
        }}>{`Reeves & Teasdale, 2019`}</a></li>
    </ul>
    <p><em parentName="p">{`Last update: 2021 May 3`}</em></p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "../other-material"
          }}>{`Return to Global Thoughts page`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "../../gondwana"
          }}>{`Return to Gondwana main page`}</a></p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      